import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserLogin } from '../models/user';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const TOKEN_KEY = 'auth-token'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public authenticationState = new BehaviorSubject(false);
  private getTokenAction: string;

  constructor(
    private _storage: Storage,
    private _plataform: Platform,
    private _http: HttpClient,
    private _router: Router
  ) { 
    this._plataform.ready().then(() => {
      this.checkToken();
    });

    this.getTokenAction = 'getToken';
  }

  checkToken() {
    this._storage.get(TOKEN_KEY).then(res => {
      if(res) {
        this.authenticationState.next(true);
      }
    });
  }

  login(user: UserLogin) {
    const usernameWithNoMask = this.removeUserNameMask(user.username);
    return this._http.get<any>(`${environment.apiUrl}${this.getTokenAction}&username=${usernameWithNoMask}&password=${user.password}`);
  }

  removeUserNameMask(username: string) {
    return username.replace(/\D+/g, '');
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  getToken() {
    return this._storage.get(TOKEN_KEY);
  }

  setToken(token: string) {
    this._storage.set(TOKEN_KEY, token);
  }

  logout() {
    this._storage.remove(TOKEN_KEY);
    this.authenticationState.next(false);
    this._router.navigate(['/home']);
  }
}
